import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Item, InventoryService } from '../services/inventory.service';

@Component({
  selector: 'app-item-add',
  templateUrl: './item-add.component.html',
  styleUrls: ['./item-add.component.scss'],
})
export class ItemAddComponent implements OnInit {

  @Input() inventoryId: string;
  item: Item;

  response: { type: string; message: string; } = { type: "", message: "" };

  constructor(
    private inventoryService: InventoryService,
    private modalCtrl: ModalController,
    public toastController: ToastController) {
  }

  ngOnInit() {
    this.initItem();
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  initItem() {
    // if (this.item == null) {
    this.item = {
      id: null,
      status: 'bought',
      name: null,
      brand: null,
      purchased_amt: null,
      purchased_on: new Date().toISOString(),
      listed_amt: null,
      listed_on: null,
      listing_fee: null,
      sold_amt: null,
      sold_on: null,
      shipping_amt: null,
      measurements: {
        size: null,
        arms: null,
        bust: null,
        height: null,
        hip: null,
        inseam: null,
        length: null,
        rise: null,
        shoulder: null,
        waist: null
      }
    };
  }

  save() {
    this.inventoryService.addItem(this.inventoryId, this.item)
      .then(res => {
        console.log('here');
        this.presentToast(this.item.name +  " Successfully Saved!");
        this.initItem();
      }).catch(err => {
        this.response = { type: 'error', message: err.message };
      });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
